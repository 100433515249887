<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import 'vue2-datepicker/locale/pt-br';
import PermissionService from '@/services/permission'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Acessos e Permissões",
  },
  components: { Layout, PageHeader },
  directives: { money: VMoney },
  data() {
    return {
      title: "Acessos e Permissões",
      loading: false,
      users: [],
      user: {
        permissions: {
          user: false,
          notifications: false,
        }
      },
      departments: [],
      modalError: '',
      userToRemove: '',

      searchValue: '',
      searchActive: false,
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'name', sortable: false, label: 'Nome' },
        { key: 'user', sortable: false, label: 'Usuário' },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true) {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const usersResult = await PermissionService.getUsers(this.searchValue);
        this.users = usersResult.data;

        // this.count = usersResult.data.count;
        // this.currentCount = usersResult.data.currentCount;
        // this.currentPage = usersResult.data.currentPage;
        // this.totalPages = usersResult.data.totalPages;
        // this.perPage = usersResult.data.perPage;
        // this.startIndex = usersResult.data.startIndex;
        // this.endIndex = usersResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar os usuários');
      }
    },
    async saveUser() {
      this.modalError = '';

      if (!this.user.user) {
        return this.modalError = 'Informe os dados de login do usuário';
      }

      if (!this.user.name) {
        return this.modalError = 'Informe o nome do usuário';
      }

      if (!this.user._id && !this.user.password) {
        return this.modalError = 'Informe a senha de acesso do usuário';
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.user._id) {
          await PermissionService.updateUser(this.user);
        } else {
          await PermissionService.createUser(this.user);
        }

        loader.hide();

        this.load(false);

        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newUser() {
      this.user = {
        permissions: {},
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editUser(item) {
      this.user = {
        _id: item._id,
        user: item.user,
        name: item.name,
        password: '',
        permissions: item.permissions || {},
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeUser() {
      this.$bvModal.hide('modal-remove');

      PermissionService.removeUser(this.userToRemove).then(() => {
        this.load(false);
        this.$toast.open('Usuário removido com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover o usuário');
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por usuário"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newUser()">
                    <i class="mdi mdi-plus mr-1"></i> Novo Usuário  
                  </button>
                </div>
              </div>
            </div>  


            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="users"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editUser(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="userToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <!-- <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="user._id ? 'Alterando Usuário' : 'Novo Usuário'" centered>
      <b-alert show variant="warning" v-if="user._id && user.password">Você está prestes a alterar a senha atual do usuário.</b-alert>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-7">
          <div class="form-group">
            <label for="user">Usuário</label>

            <input
              id="user"
              name="user"
              type="text"
              class="form-control"
              v-model="user.user"
            />
          </div>
        </div>

        <div class="col-sm-5">
          <div class="form-group">
            <label for="name">Senha</label>

            <input
              id="password"
              name="password"
              type="password"
              class="form-control"
              v-model="user.password"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="name">Nome</label>

            <input
              id="name"
              name="name"
              type="text"
              class="form-control"
              v-model="user.name"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="name">Permissões</label>

            <div class="row">
              <div class="col checkbox-list align-self-start">
                <b-form-checkbox v-model="user.permissions.users" switch class="mb-1">
                  <label>Usuários/Clientes</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.notifications" switch class="mb-1">
                  <label>Notificações</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.banners" switch class="mb-1">
                  <label>Banners</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.images" switch class="mb-1">
                  <label>Fotos</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.paymentRules" switch class="mb-1">
                  <label>Regras de Pagamento</label>
                </b-form-checkbox>
              </div>

              <div class="col checkbox-list align-self-start">
                 <b-form-checkbox v-model="user.permissions.productLimitRules" switch class="mb-1">
                  <label>Limite de Produtos</label>
                </b-form-checkbox>

                 <b-form-checkbox v-model="user.permissions.productQuantityDiscount" switch class="mb-1">
                  <label>Desconto por Quantidade</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.campaigns" switch class="mb-1">
                  <label>Campanhas</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.permissions" switch class="mb-1">
                  <label>Acesso e Permissões</label>
                </b-form-checkbox>

                <b-form-checkbox v-model="user.permissions.settings" switch class="mb-1">
                  <label>Configurações</label>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveUser()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Usuário" title-class="font-18" centered>
      <p>Confirma a exclusão do usuário?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeUser">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }

  .checkbox-list {
    display: grid;

    .custom-switch {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
</style>